<template>
  <div class="hanconfig-page">
    <Nav />
    <div class="top">
      <img src="../../image/hanConfig-detail01.png" alt="" />
      <div class="shadow"></div>
    </div>
    <div class="title-box">
      <h1 class="title">HARTING产品配置器</h1>
      <p class="tag">HARTING产品可以通过多种方式组合使用。</p>
      <svg
        class="arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
          fill="white"
          fill-opacity="0.7"
        />
      </svg>
    </div>
    <div class="hanconfig-wrap">
      <p class="tip">此页面暂不支持手机端访问，请使用电脑端访问哦</p>
      <div class="product-list">
        <div v-for="(item, index) in productList" :key="index" class="product">
          <img :src="item.imgUrl" alt="" />
          <div class="text">
            <a href="https://baidu.com">{{ item.text }}</a>
          </div>
        </div>
      </div>
    </div>
    <Footer class="custom-footer"/>
    <div class="empty"></div>
  </div>
</template>
<script>
import Footer from "../../component/footer.vue";
import Nav from "../../component/nav.vue";
export default {
  name: "HANCONFIG",
  components: {
    Footer,
    Nav
  },
  data() {
    return {
      productList: [
        {
          imgUrl: require("../../image/hanconfig-detail02.png"),
          text: "Industrial Connectors",
        },
        {
          imgUrl: require("../../image/hanconfig-detail03.png"),
          text: "Circular Connectors M17 & M23",
        },
        {
          imgUrl: require("../../image/hanconfig-detail04.png"),
          text: "har-modular®",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.hanconfig-page {
  width: 100%;
  min-height: 100vh;
  background: @common-bg;
  .top {
    position: relative;
    overflow: hidden;
    img {
      display: block;
      height: 160px;
      width: 100%;
      object-fit: cover;
    }
    .shadow {
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 40px;
      background: linear-gradient(
        180deg,
        rgba(205, 215, 222, 0) 0%,
        #cdd7de 100%
      );
    }
  }
  .title-box {
    padding: 0 32px 32px 32px;
    .title {
      margin-bottom: 8px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
    .tag {
      color: #535359;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
  }
  .hanconfig-wrap {
    box-sizing: border-box;
    padding: 40px 24px 0 24px;
    width: 100%;
    height: 400px;
    border-radius: 40px 40px 0px 0px;
    border-top: 2px solid #fff;
    background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
    .tip {
      margin-bottom: 32px;
      color: #212027;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
    .product {
      margin-bottom: 16px;
      display: flex;
      padding: 16px;
      align-items: center;
      border-radius: 12px;
      border: 1px solid #fff;
      background: #f0f3f4;
      img {
        margin-right: 10px;
        display: block;
        width: 38px;
        height: 38px;
        object-fit: cover;
      }
      .text {
        color: #212027;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
      }
    }
  }
  .custom-footer{
    position: relative;
    top: -10px;
  }
  .empty{
    width: 100%;
    height: 50px;
  }
}
</style>
